<template>
  <div class="video-thumbnail">
    <div v-if="!isPlaying" class="thumbnail-container">
      <img :src="thumbnailUrl" :alt="title" @click="playVideo" class="thumbnail-image" />
    </div>
    <iframe
      v-if="isPlaying"
      :src="iframeSrc"
      class="responsive-video"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    thumbnailUrl: {
      type: String,
      required: true
    },
    videoId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isPlaying: false
    };
  },
  computed: {
    iframeSrc() {
      return `https://www.youtube.com/embed/${this.videoId}?autoplay=1`;
    }
  },
  methods: {
    playVideo() {
      this.isPlaying = true;
    }
  }
};
</script>

<style scoped>
.thumbnail-container,.video-thumbnail{position:relative}.video-thumbnail{cursor:pointer;max-width:100%}.thumbnail-image{max-width:290px;max-height:500px}.responsive-video{width:100%;height:505px;max-width:290px;margin:0 auto;border:none}
</style>
