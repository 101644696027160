<template>
  <div id="app" style="font-family: 'Montserrat', sans-serif; font-weight: 400;">
    <div class="background-images">
      <div v-if="screenWidth >= 1000" class="blue-bar">
        <nav class="navigation-links2 top">
          <div class="left-links" style="font-family: 'Montserrat', sans-serif; font-weight: 500;">
            <router-link to="/kursy">KURSY</router-link>
            <router-link to="/czego-uczymy">CZEGO UCZYMY</router-link>
            <router-link to="/o-nas">O NAS</router-link>
            <router-link to="/kontakt">KONTAKT</router-link>
            <router-link to="/mnemonist">MNEMONIST</router-link>
          </div>
          <div class="social-icons">
            <a href="https://www.facebook.com/Mnemonic-Academy-Szko%C5%82a-Pami%C4%99ci-i-Koncentracji-Uwagi-103251889189690/?ref=page_internal" target="_blank"><img loading="lazy" src="@/assets/SVG_Facebook.svg" alt="Facebook"></a>
            <a href="https://www.instagram.com/mnemonic._.academy/" target="_blank"><img loading="lazy" src="@/assets/SVG_Instagram.svg" alt="Instagram"></a>
            <a href="https://www.tiktok.com/@mnemonic_academy" target="_blank"><img loading="lazy" src="@/assets/SVG_tiktok.svg" alt="tiktok"></a>
            <a href="https://www.youtube.com/@MNEM0NIC_ACADEMY/featured" target="_blank"><img loading="lazy" src="@/assets/SVG_youtube.svg" alt="youtube"></a>
          </div>
        </nav>
      </div>
      <div v-else>
        <section class="p-menu1">
          <nav id="navbar" class="navigation" role="navigation">
            <input id="toggle1" type="checkbox" />
            <label for="toggle1" @click="toggleButton">
              <div v-if="isCloseButton" class="menu-button">Menu</div>
              <img loading="lazy" v-else class="close-button" src="@/assets/close-white.png" alt="Close Icon">
            </label>
            <nav class="menu1">
              <router-link to="/kursy" style="text-decoration: none; color:white;">KURSY</router-link>
              <router-link to="/czego-uczymy" style="text-decoration: none; color:white;">CZEGO UCZYMY</router-link>
              <router-link to="/o-nas" style="text-decoration: none; color:white;">O NAS</router-link>
              <router-link to="/kontakt" style="text-decoration: none; color:white;">KONTAKT</router-link>
              <router-link to="/mnemonist" style="text-decoration: none; color:white;">MNEMONIST</router-link>
              <div class="social-icons2">
                <a href="https://www.facebook.com/Mnemonic-Academy-Szko%C5%82a-Pami%C4%99ci-i-Koncentracji-Uwagi-103251889189690/?ref=page_internal" target="_blank"><img loading="lazy" src="@/assets/SVG_Facebook.svg" alt="Facebook"></a>
                <a href="https://www.instagram.com/mnemonic._.academy/" target="_blank"><img loading="lazy" src="@/assets/SVG_Instagram.svg" alt="Instagram"></a>
                <a href="https://www.tiktok.com/@mnemonic_academy" target="_blank"><img loading="lazy" src="@/assets/SVG_tiktok.svg" alt="tiktok"></a>
                <a href="https://www.youtube.com/@MNEM0NIC_ACADEMY/featured" target="_blank"><img loading="lazy" src="@/assets/SVG_youtube.svg" alt="youtube"></a>
              </div>
            </nav>
          </nav>
        </section>
      </div>
    </div>
    <div class="container">
      <div class="row align-items-center">
        <div class="about-text">
          <h5 class="title">
            Mnemonic Academy
          </h5>
          <h1 class="description">
            Zespół Mnemonic Academy składa się z doświadczonych sportowców pamięciowych, 
            odnoszących sukcesy na zawodach pamięciowych zarówno w Polsce, jak i na arenie 
            międzynarodowej. Celem Mnemonic Academy jest przekazywanie uczniom naszej wiedzy 
            i umiejętności. Poprzez trening pamięci, koncentracji i kreatywności pragniemy 
            pomóc dzieciom i młodzieży przyspieszyć i uefektywnić ich naukę, aby mogły lepiej 
            radzić sobie w szkole i na studiach. Dorosłym natomiast nasze zajęcia mają pomóc 
            sprostać wyzwaniom ery informacji w życiu codziennym i zawodowym. Jako trenerzy 
            pamięci chcemy zapewnić podopiecznym wszechstronny trening umysłowy, bo mózg 
            wytrenowany to mózg sprawny na długie lata.
          </h1>
        </div>
        <div class="img-center2">
          <img loading="lazy" src="../assets/Mnemonic_academy_trojka.webp" class="responsive2" alt="Pan American Open 2022" width="360px">  
        </div>
      </div>
    </div>
    <div v-if="screenWidth >= 1220">
    <div class="container">
      <div class="row align-items-center">
        <div class="img-center2">
          <img loading="lazy" src="../assets/Ewelina_zdjecie.webp" class="responsive2" alt="Pan American Open 2022" width="360px">  
        </div>
        <div class="about-text">
          <h5 class="title">
            Ewelina Preś
          </h5>
          <h1 class="description">
            <strong class="bold-text">Wicemistrzyni Europy</strong>, mistrzyni Polski Pamięci 2020 i 2021. Rekordzistka Polski m.in. w zapamiętywaniu imion, liczb, cyfr binarnych, kart, dat, słów czy fikcyjnych słów. Zdobywczyni dwóch złotych oraz jednego srebrnego medalu na Olimpiadzie Sportów Umysłowych 2022 w Londynie, a tym samym zwyciężczyni MSO Memory Championships 2022. Laureatka trzeciego miejsca na Swedish Memory Open 2021. Była tancerka tańca towarzyskiego. Studiuje Matematykę i Analizę Danych na Politechnice Warszawskiej, a w wolnych chwilach uprawia sport.
          </h1>
        </div>
      </div>
    </div>
    </div>
    <div v-else>
      <div class="container">
        <div class="row align-items-center">
          <div class="about-text">
            <h5 class="title">
              Ewelina Preś
            </h5>
            <h1 class="description">
              <strong class="bold-text">Wicemistrzyni Europy</strong>, mistrzyni Polski Pamięci 2020 i 2021. Rekordzistka Polski m.in. w zapamiętywaniu imion, liczb, cyfr binarnych, kart, dat, słów czy fikcyjnych słów. Zdobywczyni dwóch złotych oraz jednego srebrnego medalu na Olimpiadzie Sportów Umysłowych 2022 w Londynie, a tym samym zwyciężczyni MSO Memory Championships 2022. Laureatka trzeciego miejsca na Swedish Memory Open 2021. Była tancerka tańca towarzyskiego. Studiuje Matematykę i Analizę Danych na Politechnice Warszawskiej, a w wolnych chwilach uprawia sport.
            </h1>
          </div>
          <div class="img-center2">
            <img loading="lazy" src="../assets/Ewelina_zdjecie.webp" class="responsive2" alt="Pan American Open 2022" width="360px">  
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row align-items-center">
        <div class="about-text">
          <h5 class="title">
            Jan Zoń
          </h5>
          <h1 class="description">
            <strong class="bold-text">Trzykrotny Mistrz Polski</strong> w zapamiętywaniu, brązowy medalista Olimpiady Sportów Umysłowych 2021 w sporcie pamięciowym. Wielokrotny rekordzista Polski, m.in. w zapamiętywaniu kart i obrazów. Czołowy reprezentant naszego kraju na zawodach międzynarodowych, w tym na Mistrzostwach Świata. Światowej klasy zawodnik na zawodach pamięciowych formatu Memory League. Pasjonuje się wszelkiego rodzaju grami i zagadkami umysłowymi, takimi jak: szachy, sudoku czy łamigłówki. Studiuje Informatykę na Uniwersytecie Jagiellońskim.
          </h1>
        </div>
        <div class="img-center2">
          <img loading="lazy" src="../assets/Janek_zdjecie.webp" class="responsive2" alt="Pan American Open 2022" width="360px">  
        </div>
      </div>
    </div>
    <div v-if="screenWidth >= 1220">
      <div class="container">
        <div class="row align-items-center">
          <div class="img-center2">
            <img loading="lazy" src="../assets/Lukasz_zdjecie2.webp" class="responsive2" alt="Pan American Open 2022" width="360px">  
          </div>
          <div class="about-text">
            <h5 class="title">
              Łukasz Chwałkowski
            </h5>
            <h1 class="description">
              Pomysłodawca i inicjator Mnemonic Academy. <strong class="bold-text">Rekordzista Polski</strong> w zapamiętywaniu kolorów ze słuchu i II Wicemistrz Polski Pamięci. Zdobywca złotego medalu mistrzostw Polski w konkurencji losowe słowa. Reprezentant Polski na zawodach pamięciowych Swedish Memory Open 2021 oraz na Olimpiadzie Sportów Umysłowych 2022 w Londynie. Pasjonat sztuki dedukcji i rozwoju osobistego. Studiuje Prawo na Uniwersytecie Ekonomicznym w Krakowie, a w wolnym czasie uwielbia chodzić po górach.
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="container">
        <div class="row align-items-center">
          <div class="about-text">
            <h5 class="title">
              Łukasz Chwałkowski
            </h5>
            <h1 class="description">
              Pomysłodawca i inicjator Mnemonic Academy. <strong class="bold-text">Rekordzista Polski</strong> w zapamiętywaniu kolorów ze słuchu i II Wicemistrz Polski Pamięci. Zdobywca złotego medalu mistrzostw Polski w konkurencji losowe słowa. Reprezentant Polski na zawodach pamięciowych Swedish Memory Open 2021 oraz na Olimpiadzie Sportów Umysłowych 2022 w Londynie. Pasjonat sztuki dedukcji i rozwoju osobistego. Studiuje Prawo na Uniwersytecie Ekonomicznym w Krakowie, a w wolnym czasie uwielbia chodzić po górach.
            </h1>
          </div>
          <div class="img-center2">
            <img loading="lazy" src="../assets/Lukasz_zdjecie2.webp" class="responsive2" alt="Pan American Open 2022" width="360px">  
          </div>
        </div>
      </div>
    </div>
    <h1 class="main-title" style="font-family: 'Abhaya Libre', serif; text-align: center;">NASZE SUKCESY</h1>
    <div class="container">
      <div class="row align-items-center">
        <div class="about-text">
          <h5 class="title">
            Pan American Open 2022
          </h5>
          <h1 class="description">
            W lipcu 2022 roku odbył się jeden z najważniejszych w ciągu roku turniejów formatu Memory League. W finałowej szesnastce najlepszych zawodników z całego świata znalazło się aż dwoje Polaków - Jan Zoń i Ewelina Preś. Jan, pokonawszy przeciwnika z Niemiec, znalazł się w ćwierćfinale zawodów i zakończył swoje zmagania wśród ośmiu najlepszych zawodników na świecie. Po turnieju udało mu się utrzymać wysokie 8. miejsce światowego rankingu pamięci ML, natomiast Ewelina awansowała na 28. miejsce tego samego rankingu.
          </h1>
        </div>
        <div class="img-center">
          <img loading="lazy" src="../assets/Pan_American_Open.png" class="responsive" alt="Pan American Open 2022" width="463px">  
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row align-items-center">
        <div class="about-text">
          <h5 class="title">
            "Niezwykli ludzie" - rosyjskie talent show
          </h5>
          <h1 class="description">
            W 2020 roku odbył się piąty sezon rosyjskiego talent show "Удивительные люди" (ros. Niezwykli ludzie). Do programu został zaproszony nasz trener Jan Zoń, który wystąpił w jednym z odcinków transmitowanych na głównym kanale rosyjskiej telewizji. Zadaniem Jana było zapamiętanie sekwencji 60 obrazów, a na jego wykonanie miał 90 sekund. Jan bezbłędnie odtworzył wszystkie obrazy, zaliczając swoje zadanie. Dzięki uznaniu publiczności udało mu się zająć 2. miejsce.
          </h1>
        </div>
        <div class="img-center">
          <img loading="lazy" src="../assets/Rosja.jpg" class="responsive" alt="Pan American Open 2022" width="463px">  
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row align-items-center">
        <div class="about-text">
          <h5 class="title">
            Mind Sports Olympiad 2022
          </h5>
          <h1 class="description">
            W sierpniu 2022 roku w Londynie odbyła się 26. Olimpiada Sportów Umysłowych. W zapamiętywaniu sportowym Polskę reprezentowali Ewelina Preś i Łukasz Chwałkowski. Ewelinie udało się wywalczyć dwa złote medale - w dyscyplinach Marathon Memory i Natural Memory - oraz jeden srebrny medal - w dyscyplinie Speed Memory. Tym samym wygrała ona klasyfikację generalną zawodów pamięciowych, zdobywając kolejny złoty medal. Jednocześnie Ewelinie udało się ustanowić aż 7 nowych rekordów Polski - w konkurencjach 30 Binary Digits, 30 min Numbers, 30 min Cards, 15 min Names, 15 min Words, 5 min Numbers oraz 5 min Dates. Łukaszowi udało się zająć 3. miejsce w konkurencji 5 min Images.
          </h1>
        </div>
        <div class="img-center">
          <img loading="lazy" src="../assets/MSO.jpg" class="responsive" alt="Pan American Open 2022" width="463px">  
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row align-items-center">
        <div class="about-text">
          <h5 class="title">
            German Open 2021
          </h5>
          <h1 class="description">
            Na Otwartych Mistrzostwach Niemiec w 2021 roku naszą drużynę reprezentowała Ewelina Preś, rywalizując m.in. z zawodnikami z Włoch, Danii i Szwecji. Ewelina zapamiętała 35 międzynarodowych imion w 5 minut, ustanawiając tym samym nowy rekord Polski i plasując się na 3. miejscu w tej konkurencji. W klasyfikacji generalnej Ewelina zajęła 6. miejsce.</h1>
        </div>
        <div class="img-center">
          <img loading="lazy" src="../assets/Niemcy.jpg" class="responsive" alt="Pan American Open 2022" width="463px">
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row align-items-center">
        <div class="about-text">
          <h5 class="title">
            Mind Sports Olympiad 2021
          </h5>
          <h1 class="description">
            Na Olimpiadzie Sportów Umysłowych w 2021 roku w konkurencji zapamiętywania sportowego nasz kraj reprezentował Jan Zoń. Podczas dwudniowej rywalizacji z najlepszymi zawodnikami z całego świata Janowi udało się m.in. pokonać dwukrotnego mistrza świata, a następnie jako pierwszy Polak w historii Jan sięgnął po brązowy medal olimpijski w zapamiętywaniu sportowym.
          </h1>
        </div>
        <div class="img-center">
          <img loading="lazy" src="../assets/MSO_sukcesy.jpg" class="responsive" alt="Pan American Open 2022" width="463px">  
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row align-items-center">
        <div class="about-text">
          <h5 class="title">
            Swedish Memory Open 2021
          </h5>
          <h1 class="description">
            W 2021 roku w Szwecji reprezentowali nas Ewelina Preś i Łukasz Chwałkowski, rywalizując z zawodnikami z różnych krajów europejskich, m.in. Francji, Niemiec, Danii i Włoch. Na tych zawodach Ewelina ustanowiła 3 rekordy Polski - w konkurencji zapamiętywania kart, liczb oraz cyfr binarnych. Zajęła 3. miejsce w klasyfikacji generalnej mistrzostw, zdobywając brązowy medal i jako pierwsza w historii reprezentantka Polski stając na podium międzynarodowych zawodów pamięciowych IAM. Łukaszowi udało się zająć 6. miejsce w konkurencji Images oraz 7. miejsce w konkurencji 5-min Numbers.
          </h1>
        </div>
        <div class="img-center">
          <img loading="lazy" src="../assets/Szwecja.jpg" class="responsive" alt="Pan American Open 2022" width="463px">  
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row align-items-center">
        <div class="about-text">
          <h5 class="title">
            IAM World Memory Championship 2018
          </h5>
          <h1 class="description">
            W 2018 roku na Mistrzostwach Świata w Wiedniu nasz kraj reprezentował Jan Zoń. Ustanowienie dwóch nowych rekordów Polski pozwoliło mu zająć 5. miejsce w konkurencji Images oraz 5. miejsce w konkurencji Speed Cards. Po trzech dniach zmagań Jan zakończył rywalizację na 14. miejscu wśród najlepszych sportowców pamięciowych na świecie.
          </h1>
        </div>
        <div class="img-center">
          <img loading="lazy" src="../assets/WMC20182.jpg" class="responsive" alt="Pan American Open 2022" width="463px"> 
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row align-items-center">
        <div class="about-text">
          <h5 class="title">
            Memory League World Tour 2021
          </h5>
          <h1 class="description">
            W 2021 odbył się cykl zawodów formatu Memory League, w którym brali udział sportowcy pamięciowi z całego świata. Jan Zoń dzięki całorocznej rywalizacji znalazł się wśród 16 najlepszych zawodników cyklu, otrzymując tym samym kwalifikację do Mistrzostw Świata formatu ML. Uzyskał tam nagrodę za najlepszy wynik w konkurencji Images i ostatecznie znalazł się wśród 12 najlepszych zawodników mistrzostw.
          </h1>
        </div>
        <div class="img-center">
          <img loading="lazy" src="../assets/Jan_Zon_world_Tour.png" class="responsive" alt="Pan American Open 2022" width="463px"> 
        </div>
      </div>
    </div>
    <a href="/polityka" class="privacy-policy-container" target="_blank">
      <router-link to="/polityka" class="privacy-policy" target="_blank">Polityka prywatności</router-link>
    </a>
    <router-view></router-view>
  </div>
</template>

<script>
import '@/assets/css/style.css';
export default {
  name: 'ONas',
  data() {
    return {
      screenWidth: window.innerWidth,
      isCloseButton: true,
    };
  },
  methods: {
    toggleButton() {
      this.isCloseButton = !this.isCloseButton;
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateScreenWidth);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateScreenWidth);
  },
};
</script>

<style scoped>
.img-center,.img-center2{margin-top:60px;margin-right:40px}.bold-text,.description,.privacy-policy-container,.title,body{font-family:Montserrat,sans-serif}body{height:100vh;margin:0}#app{background-image:url('../assets/tło.png')}.container{margin-bottom:20px}@media (max-width:768px){.container{margin-bottom:10px}}.about-text{max-width:700px;overflow:hidden;padding:0 40px;text-align:left}.title{font-size:36px;font-weight:400;color:#366799;margin-bottom:10px}.description{font-size:20px;font-weight:300;color:#fff}.img-center{text-align:center;margin-left:40px}.img-center2{text-align:center;margin-left:138px}@media (max-width:1324px){.img-center2{margin-left:40px}}.responsive,.responsive2{max-width:100%;height:auto}.row{display:flex;flex-wrap:wrap;justify-content:center}@media (max-width:700px){.title{font-size:30px}.description{font-size:16px}.about-text{padding:0 15px}.img-center,.img-center2{margin-left:15px;margin-right:15px}.responsive,.responsive2{max-width:90%}}.background-images{display:flex;flex-direction:column;height:60px;position:static;scroll-snap-type:y mandatory;overflow-y:auto}@media (max-width:999px){.background-images{height:0}}.privacy-policy-container{margin-top:10px;margin-bottom:20px;text-align:center;text-decoration:none;color:#fff;font-weight:400;font-size:14px;display:block}.bold-text{font-weight:700}.main-title{color:#fff;margin-top:150px;margin-bottom:50px;font-size:40px}@media (max-width:1070px){.main-title{margin-bottom:0;margin-top:150px}}
</style>
