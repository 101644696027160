<template>
  <div id="app" style="font-family: 'Montserrat', sans-serif; font-weight: 300;">
    <div class="background-images">
      <div v-if="screenWidth >= 1000" class="blue-bar">
        <nav class="navigation-links2 top">
          <div class="left-links" style="font-family: 'Montserrat', sans-serif; font-weight: 500;">
            <router-link to="/kursy">KURSY</router-link>
            <router-link to="/czego-uczymy">CZEGO UCZYMY</router-link>
            <router-link to="/o-nas">O NAS</router-link>
            <router-link to="/kontakt">KONTAKT</router-link>
            <router-link to="/mnemonist">MNEMONIST</router-link>
          </div>
          <div class="social-icons">
            <a href="https://www.facebook.com/Mnemonic-Academy-Szko%C5%82a-Pami%C4%99ci-i-Koncentracji-Uwagi-103251889189690/?ref=page_internal" target="_blank"><img src="@/assets/SVG_Facebook.svg" alt="Facebook"></a>
            <a href="https://www.instagram.com/mnemonic._.academy/" target="_blank"><img src="@/assets/SVG_Instagram.svg" alt="Instagram"></a>
            <a href="https://www.tiktok.com/@mnemonic_academy" target="_blank"><img src="@/assets/SVG_tiktok.svg" alt="tiktok"></a>
            <a href="https://www.youtube.com/@MNEM0NIC_ACADEMY/featured" target="_blank"><img src="@/assets/SVG_youtube.svg" alt="youtube"></a>
          </div>
        </nav>
      </div>
      <div v-else>
        <section class="p-menu1">
          <nav id="navbar" class="navigation" role="navigation">
            <input id="toggle1" type="checkbox" />
            <label for="toggle1" @click="toggleButton">
              <div v-if="isCloseButton" class="menu-button">Menu</div>
              <img v-else class="close-button" src="@/assets/close-white.png" alt="Close Icon">
            </label>
            <nav class="menu1">
              <router-link to="/kursy" style="text-decoration: none; color:white;">KURSY</router-link>
              <router-link to="/czego-uczymy" style="text-decoration: none; color:white;">CZEGO UCZYMY</router-link>
              <router-link to="/o-nas" style="text-decoration: none; color:white;">O NAS</router-link>
              <router-link to="/kontakt" style="text-decoration: none; color:white;">KONTAKT</router-link>
              <router-link to="/mnemonist" style="text-decoration: none; color:white;">MNEMONIST</router-link>
              <div class="social-icons2">
                <a href="https://www.facebook.com/Mnemonic-Academy-Szko%C5%82a-Pami%C4%99ci-i-Koncentracji-Uwagi-103251889189690/?ref=page_internal" target="_blank"><img src="@/assets/SVG_Facebook.svg" alt="Facebook"></a>
                <a href="https://www.instagram.com/mnemonic._.academy/" target="_blank"><img src="@/assets/SVG_Instagram.svg" alt="Instagram"></a>
                <a href="https://www.tiktok.com/@mnemonic_academy" target="_blank"><img src="@/assets/SVG_tiktok.svg" alt="tiktok"></a>
                <a href="https://www.youtube.com/@MNEM0NIC_ACADEMY/featured" target="_blank"><img src="@/assets/SVG_youtube.svg" alt="youtube"></a>
              </div>
            </nav>
          </nav>
        </section>
      </div>
    </div>
    <div class="content">
      <div class="left">
        <h1 class="header1" style="color:#317fbf;">Masz pytania?</h1>
        <h1 class="header1" style="color:white; margin-bottom: 10px;">Skontaktuj się z nami</h1>
        <h1 class="header1" style="color:white; margin-top: 20px;"><span style="color:#317fbf;">Napisz:</span> kontakt@mnemonicacademy.pl</h1>
        <h1 class="header1" style="color:white;"><span style="color:#317fbf;">Zadzwoń:</span> +48 575 573 588</h1>
        <h1 class="header1" style="color:white;">Napisz na nasze <span style="color:#317fbf;">social media</span>:</h1>
        <div class="icons">
          <a href="https://www.facebook.com/Mnemonic-Academy-Szko%C5%82a-Pami%C4%99ci-i-Koncentracji-Uwagi-103251889189690/?ref=page_internal" target="_blank"><img src="../assets/SVG_Facebook.svg" alt="Kurs dla dorosłych" class="course-image"></a>
          <a href="https://www.instagram.com/mnemonic._.academy/" target="_blank"><img src="../assets/SVG_Instagram.svg" alt="Kurs dla dorosłych" class="course-image"></a>
        </div>
        <h1 class="header1" style="color:white; margin-top: 20px;">Odpowiemy w ciągu <span style="color:#317fbf;">24 godzin</span> :))</h1>
      </div>
      <div class="right" style="align-items: center;">
        <h1 class="header2">Zadaj nam pytanie :))</h1>
        <form action="https://formsubmit.co/kontakt@mnemonicacademy.pl" method="POST" class="form-container">
            <div class="form-group">
                <label for="name">Imię *</label>
                <input type="text" id="name" name="name" placeholder="imię" required>
            </div>
            <div class="form-group">
                <label for="email">Email *</label>
                <input type="email" id="email" name="email" placeholder="e-mail" required>
            </div>
            <div class="form-group">
                <label for="phone">Nr telefonu </label>
                <input type="text" id="phone" name="phone" placeholder="opcjonalnie">
            </div>
            <div class="form-group">
                <label for="question">Pytanie *</label>
                <textarea id="question" name="question" placeholder="wpisz swoje pytanie" rows="4" required></textarea>
            </div>
            <div class="form-check">
                <input type="checkbox" id="privacy" name="privacy" required>
                <label for="privacy">Akceptuję <span style="color:#366799;"><router-link to="/polityka" class="akceptuje" target="_blank">politykę prywatności</router-link></span></label>
            </div>
            <input type="text" name="_honey" style="display: none;">
            <input type="hidden" name="_captcha" value="false">
            <input type="hidden" name="_next" value="https://mnemonicacademy.pl/sukces">
            <input type="hidden" name="_subject" value="Kontakt&nbsp;Nowe&nbsp;Pytanie">
            <button type="submit" class="btn-submit">Wyślij</button>
        </form>
      </div>
      <router-link to="/polityka" class="privacy-policy" target="_blank">Polityka prywatności</router-link>
    </div>
  </div>
</template>

<script>
import '@/assets/css/style-form.css';
import '@/assets/css/style.css';
export default {
  name: 'KontaktVue',
  data() {
    return {
      screenWidth: window.innerWidth,
      isCloseButton: true,
    };
  },
  methods: {
    toggleButton() {
      this.isCloseButton = !this.isCloseButton;
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateScreenWidth);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateScreenWidth);
  },
};
</script>

<style scoped>
#app,body{height:100vh;margin:0;padding:0}#app,.content,.left,.right{box-sizing:border-box}.header1,.header2{font-weight:500;font-family:Montserrat,sans-serif}#app,.text li{position:relative}.course-description,.header1,.header2{font-family:Montserrat,sans-serif}body{overflow:hidden}#app{overflow-y:auto;background-image:url('../assets/tło.png')}.content{display:flex;justify-content:center;align-items:flex-start;gap:20px;padding:60px 20px 20px;height:calc(100vh - 60px);flex-wrap:wrap}.left,.right{width:45%;padding:30px 50px 50px;display:flex;flex-direction:column;align-items:flex-start;align-self:flex-start}@media (max-width:1000px){.left,.right{width:70%;padding:50px}}@media (max-width:800px){.left,.right{width:70%;padding:20px}}@media (max-width:600px){.left,.right{width:100%}}.header1{color:#fff;margin-top:5px;margin-bottom:0;font-size:30px;text-align:left;line-height:1.4}.course-description,.header2,.privacy-policy{text-align:center;color:#fff}.header2{margin-top:0;font-size:31px}@media (max-width:1250px){.header1{font-size:25px}.header2{font-size:24px}}.text{list-style:none;padding-left:0;font-size:17px}.text li{color:#fff;margin-bottom:17px}.text li::before{content:'';width:5px;height:5px;background-color:#fff;position:absolute;left:-20px;top:8px}.course-description{font-weight:400}.course-image{width:80px;height:auto;margin:20px 10px 0 0}.privacy-policy{width:100%;font-size:14px;opacity:35%;margin-bottom:50px;text-decoration:none;padding-bottom:10px}.icons{display:flex;justify-content:center;align-items:center;margin-top:10px}.icons img{width:60px;height:auto;margin:0 5px 0 0}@media (max-width:520px){.header1{font-size:19px}.icons img{width:40px}}.akceptuje{text-decoration:none;color:#366799}.akceptuje:hover{color:#20507f}.background-images{display:flex;flex-direction:column;height:60px;position:static;scroll-snap-type:y mandatory;overflow-y:auto}
</style>
