<template>
  <div id="app" style="font-family: 'Montserrat', sans-serif; font-weight: 300;">
    <div class="background-images">
      <div v-if="screenWidth >= 1000" class="blue-bar">
        <nav class="navigation-links2 top">
          <div class="left-links" style="font-family: 'Montserrat', sans-serif; font-weight: 500;">
            <router-link to="/kursy">KURSY</router-link>
            <router-link to="/czego-uczymy">CZEGO UCZYMY</router-link>
            <router-link to="/o-nas">O NAS</router-link>
            <router-link to="/kontakt">KONTAKT</router-link>
            <router-link to="/mnemonist">MNEMONIST</router-link>
          </div>
          <div class="social-icons">
            <a href="https://www.facebook.com/Mnemonic-Academy-Szko%C5%82a-Pami%C4%99ci-i-Koncentracji-Uwagi-103251889189690/?ref=page_internal" target="_blank"><img src="@/assets/SVG_Facebook.svg" alt="Facebook"></a>
            <a href="https://www.instagram.com/mnemonic._.academy/" target="_blank"><img src="@/assets/SVG_Instagram.svg" alt="Instagram"></a>
            <a href="https://www.tiktok.com/@mnemonic_academy" target="_blank"><img src="@/assets/SVG_tiktok.svg" alt="tiktok"></a>
            <a href="https://www.youtube.com/@MNEM0NIC_ACADEMY/featured" target="_blank"><img src="@/assets/SVG_youtube.svg" alt="youtube"></a>
          </div>
        </nav>
      </div>
      <div v-else>
        <section class="p-menu1">
          <nav id="navbar" class="navigation" role="navigation">
            <input id="toggle1" type="checkbox" />
            <label for="toggle1" @click="toggleButton">
              <div v-if="isCloseButton" class="menu-button">Menu</div>
              <img v-else class="close-button" src="@/assets/close-white.png" alt="Close Icon">
            </label>
            <nav class="menu1">
              <router-link to="/kursy" style="text-decoration: none; color:white;">KURSY</router-link>
              <router-link to="/czego-uczymy" style="text-decoration: none; color:white;">CZEGO UCZYMY</router-link>
              <router-link to="/o-nas" style="text-decoration: none; color:white;">O NAS</router-link>
              <router-link to="/kontakt" style="text-decoration: none; color:white;">KONTAKT</router-link>
              <router-link to="/mnemonist" style="text-decoration: none; color:white;">MNEMONIST</router-link>
              <div class="social-icons2">
                <a href="https://www.facebook.com/Mnemonic-Academy-Szko%C5%82a-Pami%C4%99ci-i-Koncentracji-Uwagi-103251889189690/?ref=page_internal" target="_blank"><img src="@/assets/SVG_Facebook.svg" alt="Facebook"></a>
                <a href="https://www.instagram.com/mnemonic._.academy/" target="_blank"><img src="@/assets/SVG_Instagram.svg" alt="Instagram"></a>
                <a href="https://www.tiktok.com/@mnemonic_academy" target="_blank"><img src="@/assets/SVG_tiktok.svg" alt="tiktok"></a>
                <a href="https://www.youtube.com/@MNEM0NIC_ACADEMY/featured" target="_blank"><img src="@/assets/SVG_youtube.svg" alt="youtube"></a>
              </div>
            </nav>
          </nav>
        </section>
      </div>
    </div>

    <h1 class="main-title" style="font-family: 'Abhaya Libre', serif; text-align: center;">CZEGO UCZYMY?</h1>
    <div class="content">
      <video-thumbnail
        :thumbnail-url="require('@/assets/tiktok1.png')"
        :video-id="'KUnSHcx5AKk'"
        :title="'YouTube video player'"
      ></video-thumbnail>
      <img class="small-image" src="../assets/tarcza.png" alt="Small Image">
      <div class="text-container">
        <h2 class="text-title">Co ćwiczymy na kursach?</h2>
        <p class="text-content">
          <span style="color:#366799;">Pamięć</span> i <span style="color:#366799;">koncentrację</span>, aby nauka już nigdy nie stanowiła dla naszych kursantów wyzwania! Na kursach uczymy w jaki sposób myśleć o informacjach, by <span style="color:#366799;">były łatwe do przyswojenia</span> dla naszego mózgu. Poprzez <span style="color:#366799;">różnorodne</span>, <span style="color:#366799;">interesujące</span> i <span style="color:#366799;">nieszablonowe ćwiczenia</span> pobudzamy <span style="color:#366799;">wyobraźnię</span> i <span style="color:#366799;">kreatywność</span> uczniów, zapewniając im efektywny, a przede wszystkim angażujący i ciekawy trening umysłu.
        </p>
      </div>
    </div>

    <div v-if="screenWidth >= 1100">
      <div class="content">
        <div class="text-container">
          <h2 class="text-title">Na kursie Mnemonic Academy Twoje dziecko nauczy się:</h2>
          <ul class="text-content2">
            <li>zapamiętywania danych w <strong>kolejności chronologicznej</strong></li>
            <li>technik zapamiętywania <strong>danych liczbowych</strong>, takich jak daty czy ciągi cyfr</li>
            <li>sposobów na zapamiętywanie <strong>słówek z języka obcego</strong></li>
            <li>techniki zapamiętywania <strong>trudnych definicji</strong></li>
            <li>metod zapamiętywania <strong>imion</strong> nowo poznanych osób</li>
            <li>wykorzystywać techniki pamięciowe w nauce przedmiotów szkolnych, takich jak: <strong>historia, język polski, języki obce, matematyka, fizyka, biologia, chemia, geografia</strong></li>
          </ul>
        </div>
        <img class="small-image" src="../assets/mozg.png" alt="Small Image">
        <video-thumbnail
          :thumbnail-url="require('@/assets/tiktok2.png')"
          :video-id="'09SIC5-VoBM'"
          :title="'YouTube video player'"
        ></video-thumbnail>
      </div>
    </div>
    <div v-else>
      <div class="content">
        <video-thumbnail
          :thumbnail-url="require('@/assets/tiktok2.png')"
          :video-id="'09SIC5-VoBM'"
          :title="'YouTube video player'"
        ></video-thumbnail>
        <div class="text-container">
          <h2 class="text-title">Na kursie Mnemonic Academy Twoje dziecko nauczy się:</h2>
          <ul class="text-content2">
            <li>zapamiętywania danych w <strong>kolejności chronologicznej</strong></li>
            <li>technik zapamiętywania <strong>danych liczbowych</strong>, takich jak daty czy ciągi cyfr</li>
            <li>sposobów na zapamiętywanie <strong>słówek z języka obcego</strong></li>
            <li>techniki zapamiętywania <strong>trudnych definicji</strong></li>
            <li>metod zapamiętywania <strong>imion</strong> nowo poznanych osób</li>
            <li>wykorzystywać techniki pamięciowe w nauce przedmiotów szkolnych, takich jak: <strong>historia, język polski, języki obce, matematyka, fizyka, biologia, chemia, geografia</strong></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="content">
        <video-thumbnail
          :thumbnail-url="require('@/assets/tiktok3.png')"
          :video-id="'OiTPyyZHV64'"
          :title="'YouTube video player'"
        ></video-thumbnail>
      <img class="small-image" src="../assets/book.png" alt="Small Image">
      <div class="text-container">
          <h2 class="text-title">Zajęcia</h2>
          <p class="text-content">
            Na naszym kursie Twoje dziecko będzie miało szansę ćwiczyć swój umysł pod okiem <span style="color:#366799;">najlepszych sportowców pamięciowych </span>w Polsce. Na zajęciach przeprowadzamy z kursantami ćwiczenia, które bardzo często sami wykonujemy, aby <span style="color:#366799;">przyspieszać proces zapamiętywania</span>, <span style="color:#366799;">rozwijać</span> swoją <span style="color:#366799;">wyobraźnię</span> i <span style="color:#366799;">koncentrację</span>. Dzięki temu Twoje dziecko nie tylko nauczy się <span style="color:#366799;">technik pamięciowych przydatnych w szkole</span>, ale również rozwinie swój umysł i poszerzy swoje horyzonty.
          </p>
      </div>
    </div>

<h1 class="main-title" style="font-family: 'Abhaya Libre', serif; text-align: center;">Indywidualny kurs Mnemonic Academy składa się z 3 etapów:</h1>

<div v-if="screenWidth >= 1000" style="text-align: center; margin: 0 5%;">
  <div style="display: flex; justify-content: space-between; color: white; margin-bottom: 20px;">
    <div style="flex: 1; margin: 0 10px;">
      <p>Na pierwszym etapie kursanci uczą się podstawowych <span style="color:#366799;">technik zapamiętywania</span> oraz poznają różne <span style="color:#366799;">metody nauki</span>. Jednocześnie ćwiczą swoją <span style="color:#366799;">pamięć</span> i <span style="color:#366799;">koncentrację</span> oraz doskonalą umiejętności stosowania poznanych technik.</p>
    </div>
    <div style="flex: 1; margin: 0 10px;">
      <p>Drugi etap to zastosowanie dotychczas nabytej wiedzy w <span style="color:#366799;">nauce szkolnej</span> - dzieci uczą się wykorzystywać poznane techniki w nauce <span style="color:#366799;">języka polskiego</span>, <span style="color:#366799;">historii</span>, <span style="color:#366799;">matematyki</span>, <span style="color:#366799;">fizyki</span>, <span style="color:#366799;">nauk przyrodniczych</span> czy <span style="color:#366799;">języków obcych</span>.</p>
    </div>
    <div style="flex: 1; margin: 0 10px;">
      <p>Na ostatnim etapie badany jest <span style="color:#366799;">postęp</span> ucznia - testujemy jego pamięć i porównujemy z wynikami, jakie osiągał na początku kursu.</p>
    </div>
  </div>
  <div style="display: flex; justify-content: space-between; color: white;">
    <div style="flex: 1; display: flex; justify-content: center; margin: 0 10px;">
        <video-thumbnail
          :thumbnail-url="require('@/assets/tiktok4.png')"
          :video-id="'2ardpPPWqzY'"
          :title="'YouTube video player'"
        ></video-thumbnail>
    </div>
    <div style="flex: 1; display: flex; justify-content: center; margin: 0 10px;">
        <video-thumbnail
          :thumbnail-url="require('@/assets/tiktok5.png')"
          :video-id="'PNUkO3nbH1k'"
          :title="'YouTube video player'"
        ></video-thumbnail>
    </div>
    <div style="flex: 1; display: flex; justify-content: center; margin: 0 10px;">
        <video-thumbnail
          :thumbnail-url="require('@/assets/tiktok6.png')"
          :video-id="'u85ov8y9cAg'"
          :title="'YouTube video player'"
        ></video-thumbnail>
    </div>
  </div>
  <div style="display: flex; justify-content: space-between">
    <div style="flex: 1; display: flex; justify-content: center; margin: 0 10px;">
      <img loading="lazy" class="small-image2" src="../assets/tarcza.png" alt="Small Image">
    </div>
    <div style="flex: 1; display: flex; justify-content: center; margin: 0 10px;">
      <img loading="lazy" class="small-image3" src="../assets/mozg.png" alt="Small Image">
    </div>
    <div style="flex: 1; display: flex; justify-content: center; margin: 0 10px;">
      <img loading="lazy" class="small-image3" src="../assets/book.png" alt="Small Image">
    </div>
  </div>
</div>

<div v-else style="text-align: center; margin: 0 5%;">
  <div style="margin: 20px 0; color: white;">
    <p>Na pierwszym etapie kursanci uczą się podstawowych <span style="color:#366799;">technik zapamiętywania</span> oraz poznają różne <span style="color:#366799;">metody nauki</span>. Jednocześnie ćwiczą swoją <span style="color:#366799;">pamięć</span> i <span style="color:#366799;">koncentrację</span> oraz doskonalą umiejętności stosowania poznanych technik.</p>
        <video-thumbnail
          :thumbnail-url="require('@/assets/tiktok4.png')"
          :video-id="'2ardpPPWqzY'"
          :title="'YouTube video player'"
        ></video-thumbnail>
  </div>
  <div style="margin: 20px 0; color: white;">
    <p>Drugi etap to zastosowanie dotychczas nabytej wiedzy w <span style="color:#366799;">nauce szkolnej</span> - dzieci uczą się wykorzystywać poznane techniki w nauce <span style="color:#366799;">języka polskiego</span>, <span style="color:#366799;">historii</span>, <span style="color:#366799;">matematyki</span>, <span style="color:#366799;">fizyki</span>, <span style="color:#366799;">nauk przyrodniczych</span> czy <span style="color:#366799;">języków obcych</span>.</p>
        <video-thumbnail
          :thumbnail-url="require('@/assets/tiktok5.png')"
          :video-id="'PNUkO3nbH1k'"
          :title="'YouTube video player'"
        ></video-thumbnail>
  </div>
  <div style="margin: 20px 0; color: white;">
  <p>Na ostatnim etapie badany jest <span style="color:#366799;">postęp</span> ucznia - testujemy jego pamięć i porównujemy z wynikami, jakie osiągał na początku kursu.</p>
        <video-thumbnail
          :thumbnail-url="require('@/assets/tiktok6.png')"
          :video-id="'u85ov8y9cAg'"
          :title="'YouTube video player'"
        ></video-thumbnail>
  </div>
</div>


    <div class="privacy-policy-container">
      <router-link to="/polityka" class="privacy-policy" target="_blank">Polityka prywatności</router-link>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import VideoThumbnail from './VideoThumbnail.vue';
import '@/assets/css/style.css';

export default {
  name: 'CzegoUczymy',
  data() {
    return {
      screenWidth: window.innerWidth,
      isCloseButton: true,
    };
  },
  components: {
    VideoThumbnail
  },
  methods: {
    toggleButton() {
      this.isCloseButton = !this.isCloseButton;
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateScreenWidth);
    const script = document.createElement('script');
    script.src = "https://www.tiktok.com/embed.js";
    script.async = true;
    document.body.appendChild(script);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateScreenWidth);
  },
};
</script>

<style scoped>
#app,body{height:100vh;margin:0;padding:0}body{overflow:hidden}#app{position:relative;box-sizing:border-box;overflow-y:auto;background-image:url('../assets/tło.png')}blockquote.tiktok-embed{width:322px;border-radius:5px;border:none;margin:0;padding:0}.main-title{color:#fff;margin:50px 0}.content{display:flex;flex-direction:row;justify-content:space-between;align-items:center;margin:20px 10%}.privacy-policy{color:#fff;font-size:14px;opacity:35%;text-decoration:none}.privacy-policy-container{display:flex;justify-content:center;margin-top:8px;margin-bottom:8px}.small-image{margin-left:10px;max-width:170px;width:auto;height:auto}.small-image2{width:120px}.small-image3{width:160px}.text-container{max-width:600px;width:100%;flex:1;min-width:300px}.text-title{color:#366799}.text-content{text-align:left;color:#fff;font-size:20px}.text-content2{list-style-type:none;padding-left:0;color:#fff;font-size:20px}.text-content2 li{position:relative;padding-left:1.5em;margin-bottom:.5em}.text-content2 li::before{content:"•";position:absolute;left:0;color:#366799}.text-container .text-content,.text-container .text-content2,.text-container .text-title{font-family:Montserrat,sans-serif;font-weight:400}@media (max-width:1100px){.content{flex-direction:column;margin:20px 5%}.small-image{display:none}.text-container{margin-bottom:170px}.text-content,.text-content2{font-size:18px}}.background-images{display:flex;flex-direction:column;height:60px;position:static;scroll-snap-type:y mandatory;overflow-y:auto}.blue-bar{position:fixed;top:0;left:0;width:100%;height:50px;background-color:#317fbf;display:flex;justify-content:space-between;align-items:center;z-index:2}
</style>
