<template>
  <div id="app" style="font-family: 'Montserrat', sans-serif; font-weight: 300;">
    <router-link to="/"><img src="../assets/logo.png" alt="Logo" class="logo"></router-link>
    <div class="content">
      <div class="left">
        <h1 class="header1">Lekcja próbna za 5 zł</h1>
          <ul class="text">
              <li>lekcja (25 minut) z profesjonalnym trenerem pamięci, <span style="color:#366799;">mistrzem</span> lub <span style="color:#366799;">rekordzistą Polski</span> w szybkim zapamiętywaniu</li>
              <li>tygodniowy <strong>dostęp do</strong> <span style="color:#366799;">profesjonalnej aplikacji do ćwiczenia pamięci <strong>Mnemonist</strong></span></li>
              <li>indywidualny <strong>program treningowy</strong> poprawy pamięci</li>
              <li><strong>test pamięci</strong> kursanta</li>
          </ul>
        <img src="../assets/julka_w_elipsie.webp" alt="Kurs dla dorosłych" class="course-image">
      </div>
      <div class="right">
        <h1 class="header2">Zapisz się na lekcję</h1>
        <form action="https://formsubmit.co/kontakt@mnemonicacademy.pl" method="POST" class="form-container">
            <div class="form-row">
              <div class="form-group">
                  <label for="name">Imię i Nazwisko *</label>
                  <input type="text" id="name" name="name" placeholder="imię i nazwisko" required>
              </div>
              <div class="form-group">
                  <label for="name">Imię dziecka</label>
                  <input type="text" id="name" name="name" placeholder="opcjonalnie">
              </div>
            </div>
            <div class="form-group">
                <label for="email">Email *</label>
                <input type="email" id="email" name="email" placeholder="email" required>
            </div>
            <div class="form-row">
                <div class="form-group">
                <label for="phone">Nr telefonu *</label>
                <input type="text" id="phone" name="phone" placeholder="nr telefonu" required>
                </div>
                <div class="form-group">
                <label for="age">Wiek *</label>
                <input type="number" id="age" name="age" placeholder="wiek" required>
                </div>
            </div>
            <div class="form-check">
                <input type="checkbox" id="privacy" name="privacy" required>
                <label for="privacy">Akceptuję <span style="color:#366799;"><router-link to="/polityka" class="akceptuje" target="_blank">politykę prywatności</router-link></span></label>
            </div>
            <input type="text" name="_honey" style="display: none;">
            <input type="hidden" name="_captcha" value="false">
            <input type="hidden" name="_next" value="https://mnemonicacademy.pl/sukces">
            <input type="hidden" name="_subject" value="Lekcja&nbsp;Probna&nbsp;Nowe&nbsp;Zgłoszenie">
            <button type="submit" class="btn-submit">Zapisz się</button>
        </form>
      </div>
      <router-link to="/polityka" class="privacy-policy" target="_blank">Polityka prywatności</router-link>
    </div>
  </div>
</template>


<script>
import '@/assets/css/style-form.css';
export default {
  name: 'ZapiszSie2',
};
</script>


<style scoped>
#app,body{height:100vh;margin:0;padding:0}.content,.left,.right{box-sizing:border-box;display:flex}.header1,.header2{font-weight:500;margin-top:30px;font-family:Montserrat,sans-serif}#app,.text li{position:relative}.course-description,.header1,.header2{font-family:Montserrat,sans-serif}.course-description,.header2,.privacy-policy{text-align:center;color:#fff}body{overflow:hidden}#app{box-sizing:border-box;overflow-y:auto;background-image:url('../assets/tło.png')}.logo{position:absolute;top:25px;left:25px;width:150px}.content{justify-content:center;align-items:flex-start;gap:20px;padding:60px 20px 20px;height:calc(100vh - 60px);flex-wrap:wrap}.left,.right{width:45%;padding:30px 50px 50px;flex-direction:column;align-items:center}@media (max-width:1000px){.left,.right{width:70%;padding:50px}}@media (max-width:800px){.left,.right{width:70%;padding:0}}@media (max-width:600px){.left,.right{width:95%}}.header1{color:#fff;font-size:35px}.header2{font-size:31px}@media (max-width:400px){.header1{font-size:30px}.header2{font-size:28px}}.text{list-style:none;padding-left:20px;font-size:17px}.text li{color:#fff;margin-bottom:17px}.text li::before{content:'';width:5px;height:5px;background-color:#fff;position:absolute;left:-20px;top:8px}.course-description{font-weight:400}.course-image{width:80%;height:auto;margin:20px 0}.privacy-policy{width:100%;font-size:14px;opacity:35%;margin-bottom:50px;text-decoration:none;padding-bottom:10px}.akceptuje{text-decoration:none;color:#366799}.akceptuje:hover{color:#20507f}.form-container{max-width:460px;margin:50px auto;padding:30px;border:3px solid #366799;background-color:#1d1d1d;color:#fff}
</style>
