<template>
  <div id="app" style="font-family: 'Montserrat', sans-serif; font-weight: 300;">
    <div class="background-images">
      <div v-if="screenWidth >= 1000" class="blue-bar">
        <nav class="navigation-links2 top">
          <div class="left-links" style="font-family: 'Montserrat', sans-serif; font-weight: 500;">
            <router-link to="/kursy">KURSY</router-link>
            <router-link to="/czego-uczymy">CZEGO UCZYMY</router-link>
            <router-link to="/o-nas">O NAS</router-link>
            <router-link to="/kontakt">KONTAKT</router-link>
            <router-link to="/mnemonist">MNEMONIST</router-link>
          </div>
          <div class="social-icons">
            <a href="https://www.facebook.com/Mnemonic-Academy-Szko%C5%82a-Pami%C4%99ci-i-Koncentracji-Uwagi-103251889189690/?ref=page_internal" target="_blank"><img src="@/assets/SVG_Facebook.svg" alt="Facebook"></a>
            <a href="https://www.instagram.com/mnemonic._.academy/" target="_blank"><img src="@/assets/SVG_Instagram.svg" alt="Instagram"></a>
            <a href="https://www.tiktok.com/@mnemonic_academy" target="_blank"><img src="@/assets/SVG_tiktok.svg" alt="tiktok"></a>
            <a href="https://www.youtube.com/@MNEM0NIC_ACADEMY/featured" target="_blank"><img src="@/assets/SVG_youtube.svg" alt="youtube"></a>
          </div>
        </nav>
      </div>
      <div v-else>
        <section class="p-menu1">
          <nav id="navbar" class="navigation" role="navigation">
            <input id="toggle1" type="checkbox" />
            <label for="toggle1" @click="toggleButton">
              <div v-if="isCloseButton" class="menu-button">Menu</div>
              <img v-else class="close-button" src="@/assets/close-white.png" alt="Close Icon">
            </label>
            <nav class="menu1">
              <router-link to="/kursy" style="text-decoration: none; color:white;">KURSY</router-link>
              <router-link to="/czego-uczymy" style="text-decoration: none; color:white;">CZEGO UCZYMY</router-link>
              <router-link to="/o-nas" style="text-decoration: none; color:white;">O NAS</router-link>
              <router-link to="/kontakt" style="text-decoration: none; color:white;">KONTAKT</router-link>
              <router-link to="/mnemonist" style="text-decoration: none; color:white;">MNEMONIST</router-link>
              <div class="social-icons2">
                <a href="https://www.facebook.com/Mnemonic-Academy-Szko%C5%82a-Pami%C4%99ci-i-Koncentracji-Uwagi-103251889189690/?ref=page_internal" target="_blank"><img src="@/assets/SVG_Facebook.svg" alt="Facebook"></a>
                <a href="https://www.instagram.com/mnemonic._.academy/" target="_blank"><img src="@/assets/SVG_Instagram.svg" alt="Instagram"></a>
                <a href="https://www.tiktok.com/@mnemonic_academy" target="_blank"><img src="@/assets/SVG_tiktok.svg" alt="tiktok"></a>
                <a href="https://www.youtube.com/@MNEM0NIC_ACADEMY/featured" target="_blank"><img src="@/assets/SVG_youtube.svg" alt="youtube"></a>
              </div>
            </nav>
          </nav>
        </section>
      </div>
    </div>
    
    <div class="message-box">
      Udało się!<br>Wkrótce otrzymasz od nas<br>wiadomość.
    </div>
    
    <router-link to="/polityka" class="privacy-policy" target="_blank">Polityka prywatności</router-link>
  </div>
</template>

<script>
import '@/assets/css/style.css';
export default {
  name: 'SukcesVue',
  data() {
    return {
      screenWidth: window.innerWidth,
      isCloseButton: true,
    };
  },
  methods: {
    toggleButton() {
      this.isCloseButton = !this.isCloseButton;
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateScreenWidth);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateScreenWidth);
  },
};
</script>

<style scoped>
#app,body{height:100vh;margin:0;padding:0}body{overflow:hidden}#app{position:relative;box-sizing:border-box;overflow-y:auto;background-image:url('../assets/tło.png')}.message-box{position:absolute;top:50%;left:50%;font-family:Montserrat,sans-serif;transform:translate(-50%,-50%);background-color:#fff;color:#366799;padding:70px 15px;border-radius:15px;text-align:center;font-size:30px;font-weight:700;box-shadow:0 2px 10px rgba(0,0,0,.1)}.privacy-policy{position:absolute;bottom:20px;left:50%;transform:translateX(-50%);color:#fff;font-size:14px;opacity:35%;text-decoration:none;padding-bottom:10px}.background-images{display:flex;flex-direction:column;height:60px;position:static;scroll-snap-type:y mandatory;overflow-y:auto}.akceptuje{text-decoration:none;color:#366799}.akceptuje:hover{color:#20507f}
</style>
