import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/views/HomePage.vue";
import Kursy from "@/views/KursyVue.vue";
import CzegoUczymy from "@/views/CzegoUczymy.vue";
import ONas from "@/views/ONas.vue";
import Kontakt from "@/views/KontaktVue.vue";
import Mnemonist from "@/views/MnemonistVue.vue";
import ZapiszSieMlodzierz from "@/views/ZapiszSieMlodzierz.vue";
import ZapiszSieDorosli from "@/views/ZapiszSieDorosli.vue";
import ZapiszSie2 from "@/views/ZapiszSie5 .vue";
import ZapiszSiePojedyncza from "@/views/ZapiszSiePojedyncza.vue";
import PolitykaVue from "@/views/PolitykaVue.vue";
import SukcesVue from "@/views/SukcesVue.vue";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
    meta: {
      title: 'Start | Mnemonic Academy'
    }
  },
  {
    path: "/sukces",
    name: "SukcesVue",
    component: SukcesVue,
    meta: {
      title: 'Sukces | Mnemonic Academy'
    }
  },
  {
    path: "/polityka",
    name: "PolitykaVue",
    component: PolitykaVue,
    meta: {
      title: 'Polityka | Mnemonic Academy'
    }
  },
  {
    path: "/kursy",
    name: "KursyVue",
    component: Kursy,
    meta: {
      title: 'Kursy | Mnemonic Academy'
    }
  },
  {
    path: "/czego-uczymy",
    name: "CzegoUczymy",
    component: CzegoUczymy,
    meta: {
      title: 'Czego uczymy | Mnemonic Academy'
    }
  },
  {
    path: "/o-nas",
    name: "ONas",
    component: ONas,
    meta: {
      title: 'O nas | Mnemonic Academy'
    }
  },
  {
    path: "/kontakt",
    name: "KontaktVue",
    component: Kontakt,
    meta: {
      title: 'Kontakt | Mnemonic Academy'
    }
  },
  {
    path: "/mnemonist",
    name: "MnemonistVue",
    component: Mnemonist,
    meta: {
      title: 'Mnemonist | Mnemonic Academy'
    }
  },
  {
    path: "/kursy/zapisz_sie_mlodzierz",
    name: "ZapiszSieMlodzierz",
    component: ZapiszSieMlodzierz,
    meta: {
      title: 'Formularz | Mnemonic Academy'
    }
  },
  {
    path: "/kursy/zapisz_sie_dorosli",
    name: "ZapiszSieDorosli",
    component: ZapiszSieDorosli,
    meta: {
      title: 'Formularz | Mnemonic Academy'
    }
  },
  {
    path: "/kursy/zapisz_sie_5",
    name: "ZapiszSie2",
    component: ZapiszSie2,
    meta: {
      title: 'Formularz | Mnemonic Academy'
    }
  },
  {
    path: "/kursy/zapisz_sie_pojedyncza",
    name: "ZapiszSiePojedyncza",
    component: ZapiszSiePojedyncza,
    meta: {
      title: 'Formularz | Mnemonic Academy'
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});

export default router;
