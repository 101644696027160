<template>
  <div id="app">
    <router-link to="/"><img src="../assets/logo.png" alt="Logo" class="logo"></router-link>
    <h1 class="header">Zapisz się na kurs</h1>
    <form action="https://formsubmit.co/kontakt@mnemonicacademy.pl" method="POST" class="form-container">
      <div class="form-group">
        <label for="name">Imię i Nazwisko *</label>
        <input type="text" id="name" name="name" placeholder="imię i nazwisko kursanta" required>
      </div>
      <div class="form-group">
        <label for="email">Email *</label>
        <input type="email" id="email" name="email" placeholder="email" required>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="phone">Nr telefonu *</label>
          <input type="text" id="phone" name="phone" placeholder="nr telefonu" required>
        </div>
        <div class="form-group">
          <label for="age">Wiek *</label>
          <input type="number" id="age" name="age" placeholder="wiek" required>
        </div>
      </div>
      <div class="form-check">
        <input type="checkbox" id="privacy" name="privacy" required>
        <label for="privacy">Akceptuję <span style="color:#366799;"><router-link to="/polityka" class="akceptuje" target="_blank">politykę prywatności</router-link></span></label>
      </div>
      <input type="text" name="_honey" style="display: none;">
      <input type="hidden" name="_captcha" value="false">
      <input type="hidden" name="_next" value="https://mnemonicacademy.pl/sukces">
      <input type="hidden" name="_subject" value="Kurs&nbsp;Dorosli&nbsp;Nowe&nbsp;Zgłoszenie">
      <button type="submit" class="btn-submit">Zapisz się</button>
    </form>
    <a href="/polityka" class="privacy-policy-container" target="_blank">
      <router-link to="/polityka" class="privacy-policy" target="_blank">Polityka prywatności</router-link>
    </a>
  </div>
</template>

<script>
export default {
  name: 'ZapiszSieDorosli',
};
</script>

<style scoped>
#app,body{height:100vh;margin:0;padding:0}.header,.privacy-policy-container{text-align:center;font-family:Montserrat,sans-serif;color:#fff}#app,.header,.privacy-policy-container{font-family:Montserrat,sans-serif}body{overflow:hidden}#app{position:relative;box-sizing:border-box;overflow-y:auto;background-image:url('../assets/tło.png');font-weight:300}.logo{position:absolute;top:25px;left:25px;width:150px}.privacy-policy-container{margin-top:10px;margin-bottom:20px;text-decoration:none;font-weight:400;font-size:14px;display:block}.header{font-weight:500;margin-top:70px}.form-container{max-width:460px;margin:50px auto;padding:30px;border:3px solid #366799;background-color:#1d1d1d;color:#fff}.akceptuje{text-decoration:none;color:#366799}.akceptuje:hover{color:#20507f}
</style>
