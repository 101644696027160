<template>
  <div id="app" style="font-family: 'Montserrat', sans-serif; font-weight: 400">
    <div class="background-images">
      <div v-if="screenWidth >= 1000" class="blue-bar">
        <nav class="navigation-links2 top">
          <div class="left-links" style="font-family: 'Montserrat', sans-serif; font-weight: 500;">
            <router-link to="/kursy">KURSY</router-link>
            <router-link to="/czego-uczymy">CZEGO UCZYMY</router-link>
            <router-link to="/o-nas">O NAS</router-link>
            <router-link to="/kontakt">KONTAKT</router-link>
            <router-link to="/mnemonist">MNEMONIST</router-link>
          </div>
          <div class="social-icons">
            <a href="https://www.facebook.com/Mnemonic-Academy-Szko%C5%82a-Pami%C4%99ci-i-Koncentracji-Uwagi-103251889189690/?ref=page_internal" target="_blank" rel="noopener noreferrer"><img src="@/assets/SVG_Facebook.svg" alt="Facebook"></a>
            <a href="https://www.instagram.com/mnemonic._.academy/" target="_blank" rel="noopener noreferrer"><img src="@/assets/SVG_Instagram.svg" alt="Instagram"></a>
            <a href="https://www.tiktok.com/@mnemonic_academy" target="_blank" rel="noopener noreferrer"><img src="@/assets/SVG_tiktok.svg" alt="tiktok"></a>
            <a href="https://www.youtube.com/@MNEM0NIC_ACADEMY/featured" target="_blank" rel="noopener noreferrer"><img src="@/assets/SVG_youtube.svg" alt="youtube"></a>
          </div>
        </nav>
      </div>
      
      <img v-if="screenWidth >= 600" class="full-screen-image" :style="{ 'padding-top': screenWidth >= 1000 ? '40px' : '0' }" src="@/assets/baner1.webp" alt="Baner1">
      <img v-if="screenWidth >= 600" class="full-screen-image" style="padding-top: 50px; background-color: #1d1d1b" src="@/assets/baner2.webp" alt="Baner2">
      <img v-if="screenWidth >= 600" class="full-screen-image" src="@/assets/baner3.webp" alt="Baner3">

      <div v-if="screenWidth < 1000">
        <section class="p-menu1">
          <nav id="navbar" class="navigation" role="navigation">
            <input id="toggle1" type="checkbox" />
            <label for="toggle1" @click="toggleButton">
              <div v-if="isCloseButton" class="menu-button">Menu</div>
              <img v-else class="close-button" src="@/assets/close-white.png" alt="Close Icon">
            </label>
            <nav class="menu1">
              <router-link to="/kursy" style="text-decoration: none; color:white;">KURSY</router-link>
              <router-link to="/czego-uczymy" style="text-decoration: none; color:white;">CZEGO UCZYMY</router-link>
              <router-link to="/o-nas" style="text-decoration: none; color:white;">O NAS</router-link>
              <router-link to="/kontakt" style="text-decoration: none; color:white;">KONTAKT</router-link>
              <router-link to="/mnemonist" style="text-decoration: none; color:white;">MNEMONIST</router-link>
              <div class="social-icons2">
                <a href="https://www.facebook.com/Mnemonic-Academy-Szko%C5%82a-Pami%C4%99ci-i-Koncentracji-Uwagi-103251889189690/?ref=page_internal" target="_blank" rel="noopener noreferrer"><img src="@/assets/SVG_Facebook.svg" alt="Facebook"></a>
                <a href="https://www.instagram.com/mnemonic._.academy/" target="_blank" rel="noopener noreferrer"><img src="@/assets/SVG_Instagram.svg" alt="Instagram"></a>
                <a href="https://www.tiktok.com/@mnemonic_academy" target="_blank" rel="noopener noreferrer"><img src="@/assets/SVG_tiktok.svg" alt="tiktok"></a>
                <a href="https://www.youtube.com/@MNEM0NIC_ACADEMY/featured" target="_blank" rel="noopener noreferrer"><img src="@/assets/SVG_youtube.svg" alt="youtube"></a>
              </div>
            </nav>
          </nav>
        </section>
      </div>
      
      <img v-if="screenWidth < 600" class="full-screen-image" src="@/assets/responsywna.webp" alt="Baner4">
    </div>

    <div class="bottom-bar">
      <div class="bottom-button-container">
        <router-link to="/kursy" style="text-decoration: none;"><div class="bottom-button button-one">Zapisz się na kurs</div></router-link>
        <router-link to="/kursy/zapisz_sie_5" style="text-decoration: none;"><div class="bottom-button button-two">Lekcja za 5 zł</div></router-link>
      </div>
      <router-link to="/polityka" class="privacy-policy" target="_blank" rel="noopener noreferrer">Polityka prywatności</router-link>
    </div>
    
    <router-view></router-view>
  </div>
</template>

<script>
import '@/assets/css/style.css';

export default {
  name: 'home-page',
  data() {
    return {
      screenWidth: window.innerWidth,
      isCloseButton: true,
    };
  },
  methods: {
    toggleButton() {
      this.isCloseButton = !this.isCloseButton;
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateScreenWidth);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateScreenWidth);
  },
};
</script>

<style>
#app,body{
  height:100vh;
  margin:0;
  background-color:#1d1d1d;
  padding:0
}

#app,.background-images{
  position:relative;
  overflow-y:auto
}

#app,body{
  padding:0
}

:root{
  --bottom-bar-height:55px
}

body{
  overflow:hidden
}

#app{
  box-sizing:border-box
}

.background-images{
  display:flex;
  flex-direction:column;
  height:calc(100% - var(--bottom-bar-height));
  scroll-snap-type:y mandatory;
  z-index:1
}

.responsive-video{
  height:500px;
  margin-bottom:calc(var(--bottom-bar-height) + 15px)
}

.full-screen-image{
  height:auto;
  width:100%;
  object-fit:contain;
  margin:0;
  padding:0;
  scroll-snap-align:start;
}

@media (max-width:599px){
  .full-screen-image{
    height:auto;
    scroll-snap-align:none;
  }

  .background-images{
    height:auto;
    position:static;
    scroll-snap-type:none;
    overflow-y:visible
  }
}

.bottom-bar{
  position:fixed;
  bottom:0;
  width:100%;
  background-color:#1d1d1b;
  background-size:cover;
  background-position:center;
  padding:10px 0;
  height:var(--bottom-bar-height);
  z-index:1000;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center
}

.bottom-button-container{
  display:flex;
  justify-content:center;
  align-items:center;
  width:100%;
  margin-top:8px
}

.bottom-button{
  cursor:pointer;
  width:200px;
  height:30px;
  font-size:18px;
  border-radius:6%;
  display:flex;
  justify-content:center;
  align-items:center;
  margin:0 10px
}

@media (max-width:500px){
  .bottom-button{
    width:170px;
    font-size:15px
  }
}

@media (max-width:390px){
  .bottom-button{
    font-size:14px;
    width:140px
  }
}

.button-one{
  color:#fff;
  border:3px solid #fff
}

.button-two{
  color:#317fbf;
  border:3px solid #317fbf
}

.navigation-links{
  position:fixed;
  right:32vw;
  display:flex;
  justify-content:space-between;
  width:auto;
  z-index:2
}

.close-button{
  cursor:pointer;
  background:0 0;
  border:none;
  height:35px;
  position:absolute;
  top:3.5%;
  right:30px;
  z-index:1000
}

.privacy-policy{
  color:#fff;
  font-size:14px;
  margin-top:8px;
  opacity:35%;
  text-decoration:none
}
</style>
