<template>
  <div id="app" style="font-family: 'Montserrat', sans-serif; font-weight: 400; color: #white;">
    <h1 style="font-size: 24px; font-weight: 700;">Polityka prywatności</h1>
    <p>Polityka prywatności to dokument, który opisuje w jaki sposób zbieramy, przechowujemy, wykorzystujemy, udostępniamy i chronimy dane osobowe użytkowników strony <span style="color:#366799;">www.mnemonicacademy.pl</span> (w dalszej części również nazywanej jako „Serwis Internetowy” lub „serwis”). Jest to istotny obowiązek nałożony na nas przez rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679  dnia z 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), znane również jako <strong>RODO</strong></p>

    <hr style="border-top: 1px solid #1d1d1d; margin-bottom: 20px;">

    <h2>1. Informacje ogólne - kto jest administratorem twoich danych osobowych?</h2>
    <ol>
      <li>Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod adresem URL: <strong>www.mnemonicacademy.pl</strong></li>
      <li>Operatorem serwisu oraz Administratorem danych osobowych jest: Łukasz Chwałkowski, os. Nowe 31 f, 34-400 Nowy Targ. W dalszej części dokumentu określam się jako „my” lub <strong>„Mnemonic Academy”</strong></li>
      <li>Adres kontaktowy poczty elektronicznej operatora: kontakt@mnemonicacademy.pl</li>
      <li>Operator jest Administratorem Twoich danych osobowych w odniesieniu do danych podanych dobrowolnie w Serwisie.</li>
      <li>Serwis wykorzystuje dane osobowe w następujących celach:
        <ol type="a">
          <li>Prowadzenie newslettera</li>
          <li>Prowadzenie systemu komentarzy</li>
          <li>Prowadzenie rozmów typu chat online</li>
          <li>Obsługa zapytań przez formularz</li>
          <li>Przygotowanie, pakowanie, wysyłka towarów</li>
          <li>Realizacja zamówionych usług</li>
          <li>Prezentacja oferty lub informacji</li>
        </ol>
      </li>
      <li>Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniu w następujący sposób:
        <ol type="a">
          <li>Poprzez dobrowolnie wprowadzone w formularzach dane, które zostają wprowadzone do systemów Operatora.</li>
          <li>Poprzez zapisywanie w urządzeniach końcowych plików cookie (tzw. „ciasteczka”).</li>
        </ol>
      </li>
    </ol>

    <h2>2. Informacje ogólne - w jakim celu zbieramy dane?</h2>
    <ol>
      <li>Serwis wykorzystuje dane osobowe w następujących celach:
        <ol type="a">
          <li>Przygotowanie, pakowanie, wysyłka towarów</li>
          <li>Realizacja zamówionych usług (np. prowadzenie przez nas szkoleń)</li>
          <li>Prezentacja oferty lub informacji</li>
          <li>Ewentualne ustalenie i dochodzenie roszczeń lub obrony przed nimi</li>
        </ol>
      </li>
      <li>Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniu w następujący sposób:
        <ol type="a">
          <li>Poprzez dobrowolnie wprowadzone w formularzach dane, które zostają wprowadzone do systemów Operatora.</li>
          <li>Poprzez zapisywanie w urządzeniach końcowych plików cookie (tzw. „ciasteczka”).</li>
        </ol>
      </li>
    </ol>
    
    <h2>3. Wybrane metody ochrony danych stosowane przez Operatora</h2>
    <ol>
      <li>Miejsca logowania i wprowadzania danych osobowych są chronione w warstwie transmisji (certyfikat SSL). Dzięki temu dane osobowe i dane logowania, wprowadzone na stronie, zostają zaszyfrowane w komputerze użytkownika i mogą być odczytane jedynie na docelowym serwerze.</li>
      <li>Hasła użytkowników są przechowywane w postaci hashowanej. Funkcja hashująca działa jednokierunkowo - nie jest możliwe odwrócenie jej działania, co stanowi obecnie współczesny standard w zakresie przechowywania haseł użytkowników.</li>
    </ol>

    <h2>4. Hosting</h2>
    <ol>
      <li>Serwis jest hostowany (technicznie utrzymywany) na serwerach operatora: OVH</li>
      <li>Firma hostingowa w celu zapewnienia niezawodności technicznej prowadzi logi na poziomie serwera. Zapisowi mogą podlegać:
        <ol type="a">
          <li>zasoby określone identyfikatorem URL (adresy żądanych zasobów – stron, plików),</li>
          <li>czas nadejścia zapytania,</li>
          <li>czas wysłania odpowiedzi,</li>
          <li>nazwę stacji klienta – identyfikacja realizowana przez protokół HTTP,</li>
          <li>informacje o błędach jakie nastąpiły przy realizacji transakcji HTTP,</li>
          <li>adres URL strony poprzednio odwiedzanej przez użytkownika (referer link) – w przypadku gdy przejście do Serwisu nastąpiło przez odnośnik,</li>
          <li>informacje o przeglądarce użytkownika,</li>
          <li>informacje o adresie IP,</li>
          <li>informacje diagnostyczne związane z procesem samodzielnego zamawiania usług poprzez rejestratory na stronie,</li>
          <li>informacje związane z obsługą poczty elektronicznej kierowanej do Operatora oraz wysyłanej przez Operatora.</li>
        </ol>
      </li>
    </ol>

    <h2>5. Czy twoje dane udostępniamy innym podmiotom?</h2>
    <ol>
      <li>W niektórych sytuacjach Mnemonic Academy ma prawo przekazywać Twoje dane osobowe innym odbiorcom, jeśli będzie to niezbędne do wykonania zawartej z Tobą umowy lub do zrealizowania obowiązków ciążących na Administratorze. Dotyczy to takich grup odbiorców:
        <ol type="a">
          <li>firma hostingowa na zasadzie powierzenia</li>
          <li>kurierzy</li>
          <li>operatorzy pocztowi</li>
          <li>banki</li>
          <li>operatorzy płatności</li>
          <li>firmy, świadczące usługi marketingu na rzecz Administratora</li>
        </ol>
      </li>
    </ol>
    <h2>6. Jak długo będziemy przetwarzać twoje dane?</h2>
    <ol>
      <li>
        Czas przetwarzania Twoich danych osobowych zależy od celu i podstawy prawnej, na jakiej dane te były przetwarzane. Szczegóły dotyczące okresów przetwarzania danych znajdują się poniżej:
        <ol type="a">
          <li><strong>Zgoda</strong> - jeśli Twoje dane były przetwarzane na podstawie zgody, będą przetwarzane do momentu jej wycofania. Pamiętaj jednak, że wycofanie zgody nie wpływa na działania podjęte przed jej odwołaniem.</li>
          <li><strong>Umowa</strong> - jeśli przetwarzanie danych wynika z umowy (np. złożonego zamówienia), Twoje dane będą przetwarzane przez cały czas trwania umowy, a po jej zakończeniu, przez okres odpowiadający terminowi przedawnienia roszczeń.</li>
          <li><strong>Prawnie uzasadniony interes</strong> - jeśli przetwarzanie danych opiera się na naszym prawnie uzasadnionym interesie, dane będą przetwarzane do momentu wniesienia sprzeciwu wobec przetwarzania. Nawet po wniesieniu sprzeciwu mogą jednak istnieć sytuacje, w których będziemy mogli kontynuować przetwarzanie danych, np. w celu dochodzenia, ustalenia lub obrony przed roszczeniami (w takim przypadku dane będą przetwarzane przez okres odpowiadający terminowi przedawnienia roszczeń).</li>
        </ol>
      </li>
    </ol>

    <h2>7. Jakie masz prawa związane z przetwarzaniem twoich danych?</h2>
    <ol>
      <li>Przysługuje Ci prawo żądania od Administratora:
        <ol type="a">
          <li>dostępu do danych osobowych Ciebie dotyczących,</li>
          <li>ich sprostowania,</li>
          <li>usunięcia,</li>
          <li>ograniczenia przetwarzania,</li>
          <li>oraz przenoszenia danych.</li>
        </ol>
      </li>
      <li>Przysługuje Ci prawo do złożenia sprzeciwu w zakresie przetwarzania wskazanego w pkt 1.b wobec przetwarzania danych osobowych w celu wykonania prawnie uzasadnionych interesów realizowanych przez Administratora, w tym profilowania, przy czym prawo sprzeciwu nie będzie mogło być wykonane w przypadku istnienia ważnych prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Ciebie interesów, praw i wolności, w szczególności ustalenia, dochodzenia lub obrony roszczeń.</li>
      <li>Na działania Administratora przysługuje skarga do Prezesa Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.</li>
      <li>Podanie danych osobowych jest dobrowolne, lecz niezbędne do obsługi Serwisu.</li>
      <li>W stosunku do Ciebie mogą być podejmowane czynności polegające na zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu w celu świadczenia usług w ramach zawartej umowy oraz w celu prowadzenia przez Administratora marketingu bezpośredniego.</li>
      <li>Dane osobowe nie są przekazywane od krajów trzecich w rozumieniu przepisów o ochronie danych osobowych. Oznacza to, że nie przesyłamy ich poza teren Unii Europejskiej.</li>
    </ol>

    <h2>8. Informacje w formularzach</h2>
    <ol>
      <li>Serwis zbiera informacje podane dobrowolnie przez użytkownika, w tym dane osobowe, o ile zostaną one podane.</li>
      <li>Serwis może zapisać informacje o parametrach połączenia (oznaczenie czasu, adres IP).</li>
      <li>Serwis, w niektórych wypadkach, może zapisać informację ułatwiającą powiązanie danych w formularzu z adresem e-mail użytkownika wypełniającego formularz. W takim wypadku adres e-mail użytkownika pojawia się wewnątrz adresu URL strony zawierającej formularz.</li>
      <li>Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji konkretnego formularza, np. w celu dokonania procesu obsługi zgłoszenia serwisowego lub kontaktu handlowego, rejestracji usług itp. Każdorazowo kontekst i opis formularza w czytelny sposób informuje, do czego on służy.</li>
    </ol>

    <h2>9. Logi administratora</h2>
    <ol>
      <li>Informacje o zachowaniu użytkowników w serwisie mogą podlegać logowaniu. Dane te są wykorzystywane w celu administrowania serwisem.</li>
    </ol>

    <h2>10. Istotne techniki marketingowe</h2>
    <ol>
      <li>Operator stosuje analizę statystyczną ruchu na stronie, poprzez Google Analytics (Google Inc. z siedzibą w USA). Operator nie przekazuje do operatora tej usługi danych osobowych, a jedynie zanonimizowane informacje. Usługa bazuje na wykorzystaniu ciasteczek w urządzeniu końcowym użytkownika. W zakresie informacji o preferencjach użytkownika gromadzonych przez sieć reklamową Google użytkownik może przeglądać i edytować informacje wynikające z plików cookies przy pomocy narzędzia: <a href="https://www.google.com/ads/preferences/">https://www.google.com/ads/preferences/</a></li>
      <li>Operator stosuje techniki remarketingowe, pozwalające na dopasowanie przekazów reklamowych do zachowania użytkownika na stronie, co może dawać złudzenie, że dane osobowe użytkownika są wykorzystywane do jego śledzenia, jednak w praktyce nie dochodzi do przekazania żadnych danych osobowych od Operatora do operatorów reklam. Technologicznym warunkiem takich działań jest włączona obsługa plików cookie.</li>
      <li>Operator stosuje korzysta z piksela Facebooka. Ta technologia powoduje, że serwis Facebook (Facebook Inc. z siedzibą w USA) wie, że dana osoba w nim zarejestrowana korzysta z Serwisu. Bazuje w tym wypadku na danych, wobec których sam jest administratorem, Operator nie przekazuje od siebie żadnych dodatkowych danych osobowych serwisowi Facebook. Usługa bazuje na wykorzystaniu ciasteczek w urządzeniu końcowym użytkownika.</li>
      <li>Operator stosuje rozwiązanie automatyzujące działanie Serwisu w odniesieniu do użytkowników, np. mogące przesłać maila do użytkownika po odwiedzeniu konkretnej podstrony, o ile wyraził on zgodę na otrzymywanie korespondencji handlowej od Operatora.</li>
    </ol>

    <h2>11. Informacje o plikach cookies</h2>
    <ol>
      <li>Serwis korzysta z plików cookies.</li>
      <li>Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.</li>
      <li>Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich dostęp jest operator Serwisu.</li>
      <li>Pliki cookies wykorzystywane są w następujących celach:
        <ol type="a">
          <li>utrzymanie sesji użytkownika Serwisu (po zalogowaniu), dzięki której użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać loginu i hasła;</li>
          <li>realizacji celów określonych powyżej w części "Istotne techniki marketingowe";</li>
        </ol>
      </li>
      <li>W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne” (session cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.</li>
      <li>Oprogramowanie do przeglądania stron internetowych (przeglądarka internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać zmiany ustawień w tym zakresie. Przeglądarka internetowa umożliwia usunięcie plików cookies. Możliwe jest także automatyczne blokowanie plików cookies. Szczegółowe informacje na ten temat zawiera pomoc lub dokumentacja przeglądarki internetowej.</li>
      <li>Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych Serwisu.</li>
      <li>Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu wykorzystywane mogą być również przez współpracujące z operatorem Serwisu podmioty, w szczególności dotyczy to firm: Google (Google Inc. z siedzibą w USA), Facebook (Facebook Inc. z siedzibą w USA), Twitter (Twitter Inc. z siedzibą w USA).</li>
    </ol>

    <h2>12. Zarządzanie plikami cookies - jak w praktyce wyrażać i cofnąć zgodę?</h2>
    <ol>
      <li>Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić ustawienia przeglądarki. Zastrzegamy, że wyłączenie obsługi plików cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa, utrzymania preferencji użytkownika może utrudnić, a w skrajnych przypadkach może uniemożliwić korzystanie ze stron www.</li>
      <li>W celu zarządzania ustawienia cookies wybierz z listy poniżej przeglądarkę internetową, której używasz i postępuj zgodnie z instrukcjami:
        <ol type="a">
          <li>Edge</li>
          <li>Internet Explorer</li>
          <li>Chrome</li>
          <li>Safari</li>
          <li>Firefox</li>
          <li>Opera</li>
        </ol>
      </li>
      <li>Urządzenia mobilne:
        <ol type="a">
          <li>Android</li>
          <li>Safari (iOS)</li>
          <li>Windows Phone</li>
        </ol>
      </li>
    </ol>

    <h2>13. Kontakt</h2>
    <ol>
      <li>Jeśli po zapoznaniu się z naszą polityką masz pytania dotyczące prywatności lub przetwarzania Twoich danych, prosimy o kontakt:
        <ol type="a">
          <li>Łukasz Chwałkowski</li>
          <li>Os. Nowe 31 f</li>
          <li>34-400 Nowy Targ</li>
          <li>E-mail: <a href="mailto:kontakt@mnemonicacademy.pl">kontakt@mnemonicacademy.pl</a></li>
        </ol>
      </li>
    </ol>
  </div>
</template>


<script>
export default {
  name: 'PolitykaVue',
  data() {
    return {
    };
  },
};
</script>

<style scoped>
body {
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: white;
}
#app {
  position: relative;
  height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: white;
}
</style>
